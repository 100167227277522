<template>
    <mercur-card class="full-height fhf container mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Attributes
            <template slot="actions">
                <mercur-button @click="openAddAttributeDialog" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    Add new attribute
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="border"
            :items="attributeOptions"
            :options="options"
            :quickSearch="filters.search"
            ref="grid"
        >
        </data-table>

        <mercur-dialog :is-open.sync="addAttributeOptionDialogShow" width="100vw">
            <div slot="header" v-if="addAttributeOptionDialogParams.isEdit">
                <h3 class="font-weight-normal">Edit attribute option {{addAttributeOptionDialogParams.attributeOption}}</h3>
            </div>
            <div slot="header" v-else>
                <h3 class="font-weight-normal">Add attribute option to {{addAttributeOptionDialogParams.attributeName}}</h3>
            </div>
            <form @submit.prevent="submitAddAttributeOption">
                <div slot="default">
                    <p>
                        <span v-if="addAttributeOptionDialogParams.isEdit">Please enter the new value for the attribute option.</span>
                        <span v-else>Please enter the value for the new attribute option.</span>
                        <br />Only lowercase characters, numbers and underscores are allowed
                    </p>
                    <mercur-input
                        v-model.trim="$v.addAttributeOptionDialogParams.attributeOption.$model"
                        :disabled="addAttributeOptionDialogLoading"
                        required
                        ref="addAttributeOptionInputfield"
                        autofocus
                        :class="{'form-invalid': $v.addAttributeOptionDialogParams.attributeOption.$error}">
                        Option name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeOptionDialogParams.attributeOption.format">Only alphanumeric characters and underscores are allowed (<i>a-z, 0-9 and _</i>).</span>
                            <span class="form-error" v-if="!$v.addAttributeOptionDialogParams.attributeOption.duplicate">This option already exists.</span>
                        </template>
                    </mercur-input>

                    <mercur-input
                        v-model.trim="$v.addAttributeOptionDialogParams.postfix.$model"
                        :disabled="addAttributeOptionDialogLoading"
                        :class="{'form-invalid': $v.addAttributeOptionDialogParams.postfix.$error}">
                        Postfix
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeOptionDialogParams.postfix.$error">Invalid characters</span>
                        </template>
                    </mercur-input>
                </div>

                <div slot="footer">
                    <mercur-button class="btn btn-yellow text-uppercase" @click="addAttributeOptionDialogShow = false">Close</mercur-button>
                    <mercur-button class="btn btn-yellow text-uppercase" :disabled="addAttributeOptionDialogLoading || $v.addAttributeOptionDialogParams.attributeOption.$invalid" type="submit">Save</mercur-button>
                </div>
            </form>
            <mercur-progress-bar indeterminate v-if="addAttributeOptionDialogLoading"></mercur-progress-bar>
        </mercur-dialog>
        <mercur-dialog :is-open.sync="addAttributeDialogShow">
            <div slot="header"><h3 class="font-weight-normal">{{addAttributeDialogParams.isEdit ? 'Edit' : 'Add'}} attribute </h3></div>
            <form @submit.prevent="submitAddAttribute">
                <div slot="default">
                    <p v-if="addAttributeDialogParams.isEdit">Please enter the new name for the attribute.<br />Only lowercase characters, numbers and underscores are allowed</p>
                    <p v-else>Please enter the name for the new attribute.<br />Only lowercase characters, numbers and underscores are allowed</p>
                    <mercur-input
                        v-model.trim="$v.addAttributeDialogParams.attributeName.$model"
                        :disabled="addAttributeDialogLoading"
                        required
                        autofocus
                        :class="{'form-invalid': $v.addAttributeDialogParams.attributeName.$error}">
                        Attribute name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.attributeName.format">Only alphanumeric characters and underscores are allowed (<i>a-z, 0-9 and _</i>).</span>
                        </template>
                    </mercur-input>

                    <mercur-input
                        v-if="!addAttributeDialogParams.isEdit"
                        v-model.trim="$v.addAttributeDialogParams.attributeOption.$model"
                        :disabled="addAttributeDialogLoading"
                        required
                        :class="{'form-invalid': $v.addAttributeDialogParams.attributeOption.$error}">
                        Option name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.attributeOption.format">Only alphanumeric characters and underscores are allowed (<i>a-z, 0-9 and _</i>).</span>
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.attributeOption.duplicate">This option already exists.</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-if="!addAttributeDialogParams.isEdit"
                        v-model.trim="$v.addAttributeDialogParams.attributeOption.$model"
                        :disabled="addAttributeDialogLoading"
                        required
                        :class="{'form-invalid': $v.addAttributeDialogParams.attributeOption.$error}">
                        Option name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.attributeOption.format">Only alphanumeric characters and underscores are allowed (<i>a-z, 0-9 and _</i>).</span>
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.attributeOption.duplicate">This option already exists.</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-model.trim="$v.addAttributeDialogParams.postfix.$model" :disabled="addAttributeDialogLoading"
                        :class="{'form-invalid': $v.addAttributeDialogParams.postfix.$error}">
                        Postfix
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addAttributeDialogParams.postfix.$error">Invalid characters</span>
                        </template>
                    </mercur-input>
                </div>

                <div slot="footer">
                    <mercur-button class="btn btn-yellow text-uppercase" @click="addAttributeDialogShow = false">Close</mercur-button>
                    <mercur-button class="btn btn-yellow text-uppercase" :disabled="addAttributeDialogLoading || $v.addAttributeDialogParams.$invalid" type="submit">Save</mercur-button>
                </div>
            </form>
            <mercur-progress-bar indeterminate v-if="addAttributeDialogLoading"></mercur-progress-bar>
        </mercur-dialog>
    </mercur-card>
</template>

<script>

import CONFIG from '@root/config'
import AttributeTableActionCell from '@/components/attributes/AttributeTableActionCell'
import GridHeader from '@/components/GridHeader'
import { required, requiredUnless, helpers } from 'vuelidate/lib/validators'
import DataTable from '../../components/DataTable'

export default {
    name: 'AttributesOverview',
    components: { DataTable, GridHeader },

    data () {
        return {
            url: CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS.replace('{supplierId}', this.supplierId),
            attributeOptions: null,
            filters: {
                search: '',
            },
            addAttributeDialogShow: false,
            addAttributeDialogParams: false,
            addAttributeDialogLoading: false,
            addAttributeOptionDialogShow: false,
            addAttributeOptionDialogParams: false,
            addAttributeOptionDialogLoading: false,
            options: {
                animateRows: false,
                paginationPageSize: 999999,
                frameworkComponents: {
                    'attributeTableActionCell': AttributeTableActionCell,
                },
                columns: {
                    'Attribute Name': {
                        field: 'attributeName',
                        rowGroup: true,
                        sortable: true,
                        hide: true,
                        sort: 'asc',
                    },
                    'Attribute Option': {
                        field: 'attributeOption',
                        cellRendererSelector: (params) => {
                            if (params.node.group) {
                                return {
                                    component: 'attributeTableActionCell',
                                    params: {
                                        openAddAttributeOption: (params) => {
                                            this.openAddAttributeOptionDialog(params)
                                        },
                                    },
                                }
                            }

                            return null
                        },
                    },
                },
                autoGroupColumnDef: {
                    headerName: 'Attribute',
                    sortable: true,
                    sort: 'asc',
                    comparator: (valueA, valueB) => {
                        if (valueA == null || valueB == null) {
                            return valueA - valueB
                        }
                        return valueA.localeCompare(valueB)
                    },
                },
            },
        }
    },
    validations: {
        addAttributeOptionDialogParams: {
            attributeOption: {
                required,
                format: helpers.regex('alpha', /^[0-9a-z_]+$/),
                duplicate: function (value) {
                    if (this.attributeOptions === null) {
                        return true
                    }
                    return !this.attributeOptions.some((attributeOption) => attributeOption.attributeName === this.addAttributeOptionDialogParams.attributeKey && attributeOption.option.toLowerCase() === value.toLowerCase())
                },
            },
            postfix: {},
        },
        addAttributeDialogParams: {
            attributeName: {
                required,
                format: helpers.regex('alpha', /^[0-9a-z_]+$/),
            },
            attributeOption: {
                required: requiredUnless(function () {
                    return this.addAttributeDialogParams.isEdit
                }),
                format: helpers.regex('alpha', /^[0-9a-z_]+$/),
                duplicate: function (value) {
                    if (this.attributeOptions === null) {
                        return true
                    }
                    return !this.attributeOptions.some((attributeOption) => attributeOption.attributeName === this.addAttributeDialogParams.attributeName && attributeOption.attributeOption.toLowerCase() === value.toLowerCase())
                },
            },
            postfix: {},
        },
    },

    watch: {
        'filters.search' (value) {
            this.$refs.grid.gridOptions.api.setQuickFilter(value)
        },
    },

    methods: {
        openAddAttributeOptionDialog (params) {
            this.$v.$reset()
            this.addAttributeOptionDialogShow = true
            this.addAttributeOptionDialogParams = {
                ...params,
                attributeOption: '',
                additionalData: [],
                postfix: '',
            }
        },

        openEditAttributeOptionDialog (params) {
            this.$v.$reset()
            this.addAttributeOptionDialogShow = true
            this.addAttributeOptionDialogParams = {
                ...params,
                isEdit: !!params.attributeId,
            }
        },

        openAddAttributeDialog () {
            this.$v.$reset()
            this.addAttributeDialogShow = true
            this.addAttributeDialogParams = {
                attributeName: '',
                attributeOption: '',
                additionalData: [],
                postfix: '',
            }
        },
        submitAddAttributeOption () {
            const params = this.addAttributeOptionDialogParams
            let url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.ADD_OPTION

            if (params.isEdit) {
                url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.UPDATE_OPTION.replace('{attributeId}', params.attributeId)
            }

            this.addAttributeOptionDialogLoading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                this.addAttributeOptionDialogShow = false
                this.$root.$emit('notification:global', {
                    message: `Attribute option was ${params.isEdit ? 'updated' : 'added'}`,
                })

                this.fetchAllAttributes(true)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `${params.isEdit ? 'Editing' : 'Adding'} attribute option failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.addAttributeOptionDialogLoading = false
            })
        },
        submitAddAttribute () {
            const params = this.addAttributeDialogParams
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.ADD_OPTION

            this.addAttributeDialogLoading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                if (data.message === 'products.attributeOption.add.successful') {
                    this.addAttributeDialogShow = false
                    this.$root.$emit('notification:global', {
                        message: 'Attribute was added',
                    })

                    this.fetchAllAttributes(true)
                }
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Adding attribute failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.addAttributeDialogLoading = false
            })
        },
        handleAttributes () {
            const allAttributeOptions = []
            Object.entries(this.$store.state.productAttributes.productAttributes).forEach(([attributeKey, attributeOptions]) => {
                allAttributeOptions.push(...attributeOptions.map(attributeOption => {
                    attributeOption.attributeName = attributeKey
                    attributeOption.attributeOption = attributeOption.option
                    return attributeOption
                }).sort((a, b) => a.attributeOption.localeCompare(b.attributeOption)))
            })
            setTimeout(() => {
                this.attributeOptions = allAttributeOptions.sort((a, b) => a.attributeName.localeCompare(b.attributeName))
            }, 1)
        },
        fetchAllAttributes (force = false) {
            if (force || Object.keys(this.$store.state.productAttributes.productAttributes).length === 0) {
                this.$store.dispatch('productAttributes/fetchProductAttributes').then(() => {
                    this.handleAttributes()
                })
            } else {
                this.handleAttributes()
            }
        },
    },
    created () {
        this.fetchAllAttributes()
    },
}
</script>
